<template>
  <centered-column-layout class="relative">
    <current-module />

  <!--    <page-title :tags="getTagsByFieldKey('integrations')">-->
  <!--      <template-->
  <!--        v-if="!getTagsByFieldKey('referral_system')"-->
  <!--        #custom-button-->
  <!--      >-->
  <!--        <a-button-->
  <!--          class="btn-success"-->
  <!--          @click="$store.dispatch('saveChatConfig')"-->
  <!--        >-->
  <!--          {{$t('chat_config_save_button')}}-->
  <!--        </a-button>-->
  <!--      </template>-->
  <!--    </page-title>-->

  <!--    <div-->
  <!--      v-if="getTagsByFieldKey('integrations')"-->
  <!--      style="height: 300px;"-->
  <!--      class="relative"-->
  <!--    >-->
  <!--      <not-available-options-overlay-->
  <!--        class="shadow"-->
  <!--        style="margin: 0 1rem;"-->
  <!--      />-->
  <!--    </div>-->
  <!--    <template v-else>-->
  <!--      <div class="flex flex-col">-->
  <!--        <a-button-->
  <!--          ref="generateTokenButton"-->
  <!--          :loading="generateTokenLoading"-->
  <!--          type="primary"-->
  <!--          @click="generateToken"-->
  <!--        >-->
  <!--          {{ hiddenToken ? $t('external_integrations_generate_token_again') : $t('external_integrations_generate_token') }}-->
  <!--        </a-button>-->
  <!--        <config-field :mini-help-message="$t('external_integrations_generate_token_mini_help_message')" />-->
  <!--      </div>-->

  <!--      <a-card-->
  <!--        v-if="hiddenToken"-->
  <!--        class="mt-2"-->
  <!--      >-->
  <!--        <config-field-->
  <!--          :title="$t('external_integrations_token')"-->
  <!--          class="mb-2"-->
  <!--          inline-->
  <!--        >-->
  <!--          <code>-->
  <!--            {{ hiddenToken }}-->
  <!--          </code>-->
  <!--        </config-field>-->
  <!--      </a-card>-->

  <!--      <a-modal-->
  <!--        v-model="isShowTokenPopupOpen"-->
  <!--        :footer="null"-->
  <!--      >-->
  <!--        <a-card :bordered="false">-->
    <!--            <a-alert show-icon>-->
    <!--              <div-->
    <!--                slot="message"-->
    <!--                v-html="$t('external_integrations_attention_message')"-->
    <!--              />-->
    <!--            </a-alert>-->
  <!--          <config-field-->
  <!--            :title="$t('external_integrations_token_title')"-->
  <!--            class="mt-2"-->
  <!--          >-->
  <!--            <div class="flex flex-col">-->
  <!--              <code-->
  <!--                v-if="token"-->
  <!--                class="block max-w-full overflow-auto mt-2 mb-2"-->
  <!--              >-->
  <!--                {{ token }}-->
  <!--              </code>-->
  <!--              <a-button-->
  <!--                type="primary"-->
  <!--                icon="copy"-->
  <!--                @click="copyToken"-->
  <!--              >-->
  <!--                {{ $t('external_integrations_copy_token') }}-->
  <!--              </a-button>-->
  <!--              <a-alert-->
  <!--                v-if="isTokenCopied"-->
  <!--                show-icon-->
  <!--                type="success"-->
  <!--                class="mt-2"-->
  <!--              >-->
  <!--                <span-->
  <!--                  slot="message"-->
  <!--                  v-html="$t('external_integrations_token_was_copied')"-->
  <!--                />-->
  <!--              </a-alert>-->
  <!--            </div>-->
  <!--          </config-field>-->
  <!--        </a-card>-->
  <!--      </a-modal>-->
  <!--      <a-card-->
  <!--        v-if="$store.state.chatState.chat.version >= 6000"-->
  <!--        class="api_integration mt-base"-->
  <!--      >-->
  <!--        <text-input-->
  <!--          :setup="{-->
  <!--            'func': configInputSetup,-->
  <!--            'args': {-->
  <!--              'model': stateChatConfigPath,-->
  <!--              'key': 'api_notify_url',-->
  <!--              'validation': 'required|url',-->
  <!--              'hasAnchor': true,-->
  <!--            }-->
  <!--          }"-->
  <!--        />-->
  <!--        <div-->
  <!--          v-for="(typeKey, index) of Object.keys(types)"-->
  <!--          :key="index"-->
  <!--        >-->
  <!--          <checkbox-input-->
  <!--            :setup="{-->
  <!--              'func': modelSetter,-->
  <!--              'args': {-->
  <!--                'model': types,-->
  <!--                'key': typeKey,-->
  <!--                'hasAnchor': true,-->
  <!--              }-->
  <!--            }"-->
  <!--          />-->
  <!--        </div>-->
  <!--      </a-card>-->
  <!--    </template>-->
  </centered-column-layout>
</template>

<script>
import Api from '@/includes/logic/Api'
import CenteredColumnLayout from '@/components/CenteredColumnLayout'
import { getTariffTranslationKey } from "@/includes/helpers/tariffHelper";
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay'
import { InputSetups } from "@/mixins/input-setups";
import { errorNotification } from "@/includes/NotificationService";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import CurrentModule from "@/components/Modules/components/CurrentModule";
import TempCurrentModuleMixin from "@/includes/logic/Modules/mixins/TempCurrentModuleMixin";

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { UseFields } from "piramis-base-components/src/components/Pi";
import { copyTokenToClipboard } from 'piramis-base-components/src/logic/helpers/copy-to-clipboard'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

export default {
  name: "external-integrations",
  mixins: [ UseFields, InputSetups, TariffsTagsHelper, TempCurrentModuleMixin ],
  components: {
    CurrentModule,
    NotAvailableOptionsOverlay,
    CenteredColumnLayout,
    PageTitle,
    ConfigField
  },
  data() {
    return {
      copyTokenToClipboard,
      types: {
        EnterNewMember: false,
        ReturnNewMember: false,
        CaptchaNotVerifiedKick: false,
        UserReEnterBan: false,
        KickSecondInactive: false,
        KickInactive: false,
        MemberLeave: false,
        MemberKicked: false
      },
      isShowTokenPopupOpen: false,
      token: null,
      hiddenToken: this.$store.state.chatState.chat.access_token ? this.$store.state.chatState.chat.access_token : null,
      isTokenCopied: false,
      generateTokenLoading: false
    }
  },
  watch: {
    types: {
      handler: function (value) {
        this.$store.commit("EXEC_CALLBACK_IN_STORE", {
          callback: () => {
            this.$set(this.$store.state.chatState.chat.config, 'api_notify_types', Object.keys(value).filter(k => this.types[k]))
          }
        })
      },
      deep: true
    }
  },
  methods: {
    modelSetter(args) {
      args.setter = (value) => {
        args.model[args.key] = value
      }
      return args
    },
    getTariffTranslationKey,

    copyToken() {
      copyTokenToClipboard(this.token)
      this.isTokenCopied = true
    },

    generateToken() {
      this.generateTokenLoading = true

      Api.generateAccessToken('tg', { chat_id: this.$store.state.chatState.chat.chat_id })
        .then((res) => {
          this.token = res.data.access_token

          this.hiddenToken = `${ this.token.slice().substring(0, 5) }**********${ this.token.slice().substring(this.token.length - 5) }`

          this.isShowTokenPopupOpen = true

          const unwatchShowTokenPopupOpen = this.$watch('isShowTokenPopupOpen', (newValue) => {
            if (!newValue) {
              this.token = null
              this.isTokenCopied = false
              unwatchShowTokenPopupOpen()
            }
          })

        })
        .catch(errorNotification)
        .finally(() => {
          this.generateTokenLoading = false
        })
    }
  },
  mounted() {
    // if (!this.getTagsByFieldKey('integrations')) {
    this.setCurrentModule('ApiNotifyHandlerModule')
    // }
    //
    // this.$store.state.chatState.chat.config.api_notify_types.slice().forEach(type => this.types[type] = true)
  },
}
</script>

<style scoped>

</style>
